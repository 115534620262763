<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="dark"
  >
    <b-navbar-brand
      :to="{ name: 'info'}"
      style="text-decoration: underline;"
    >
      <font-awesome-icon
        icon="coffee"
        style="margin-right: 0.5rem;"
      />Viktor Melnyk
    </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'info'}">Home</b-nav-item>
          <b-nav-item href="#" disabled>Certificates</b-nav-item>
          <b-nav-item href="#" disabled>Blog</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

  </b-navbar>
</template>

<script>
export default {
  name: "Header",
};
</script>
