<template>
  <div id="app">
    <header-app />
    <b-container fluid style="margin-top: 2.5rem;">
      <b-row align="center" cols=1 cols-sm=1>
        <b-col 
          col
          lg="3"
          style="padding: 0rem;"
        >
          <sidebar-app />
        </b-col>
        <b-col col lg="8" sm="12" md="12">
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
    <footer-app style="position: fixed; bottom: 0;"/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue"
import Sidebar from "@/components/Sidebar.vue"

export default {
  name: 'App',
  components: {
    HeaderApp: Header,
    FooterApp: Footer,
    SidebarApp: Sidebar,
  }
}
</script>

<style>
  #app {
    min-height: 100vh;
    background-color: rgba(180, 180, 180, 0.5);
  }
</style>
