<template>
  <footer>
    <div class="container">
      <span class="attribution">
        &copy; All rights reserved.
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
