import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      name: "info",
      path: "/",
      component: () => import("@/components/Information.vue"),
    }
  ]
});
